import React from 'react';
import {NavLink} from "react-router-dom";
import './information.scss';

const Information = () => {

    const informationTexts = [
        'ООО НПФ «Техполиком» - ведущая компания на рынке клеев и клеевых препрегов в России. Мы обеспечиваем качественные решения для предприятий авиастроительного комплекса по всей стране.',
        'Мы гордимся нашими двумя современными технологическими площадками в Москве и Ярославле, а также нашей собственной лабораторией, оснащенной современным исследовательским и испытательным оборудованием.',
        'Мы успешно сотрудничаем с нашими клиентами по договорам, заключаемым в рамках ГОЗ, и участвуем в проведении конкурентных процедур на множестве торговых площадок. Наша компания строго соблюдает все требования и нормы законодательства в сфере размещения Гособоронзаказа.',
        'Более 30 лет опыта и профессионализма позволяют нам постоянно улучшать нашу продукцию и оставаться лидерами на рынке. Мы готовы предоставить качественные решения для Ваших задач в области клеевых композиций.',
    ]

    return (
        <section className="section information" id="about">
            <div className="container information__wrapper">
                <h2>О компании</h2>
                <ul className="information__list">
                    {informationTexts.map((item, index)=> (
                        <li key={index} className="information__item">
                            <p>{item}</p>
                        </li>
                    ))}
                    <li className="information__item">
                        <NavLink to={'/about'}>Узнать больше...</NavLink>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default Information;
