import anikhovskaya from '../../assets/people/anikhovskaya-new.png';
import ivanova from '../../assets/people/raisa.png';
import batizat from '../../assets/people/batizat.png';
import serejenkov from '../../assets/people/serejenkov.png';
import lyamin from '../../assets/people/lyamin.png';
import sluckii from '../../assets/people/sluckii.png';
import solyonov from '../../assets/people/solyonov.png';
import nujdin from '../../assets/people/nujdin.png';
import pilyasova from '../../assets/people/pilyasova-new.png';
import mishina from '../../assets/people/nadya.png';
import ustinova from '../../assets/people/ustinova.png';
import dementieva from '../../assets/people/dementieva.png';

const staffData = {
    leader: [
        {
            name: 'Аниховская Любовь Ивановна',
            position: 'Генеральный директор',
            description: 'Кандидат технических наук, лауреат Государственной премии РФ в области науки и техники (2001 г.) Имеет более 35 авторских свидетельств СССР и патентов РФ, более 100 научных работ. При непосредственном участии были разработаны высокоэластичные пленочные клеи конструкционного назначения; технологические процессы изготовления пленочных клеев; композиционные материалы клеевые марок КМК (клеевые препреги); алюмостеклопластики (СИАЛ) конструкционного назначения; экологически чистая технология склеивания авиационных конструкций и принципиально новые авиационные конструкции с высоким ресурсом и надежностью. Материалы и технологические процессы, разработанные под руководством и при непосредственном участии Л.И. Аниховской внедрены практически во всех изделиях авиакосмической техники.',
            avatar: anikhovskaya,
        },
        {
            name: 'Дементьева Лидия Александровна',
            position: 'Заместитель генерального директора ООО НПФ "Техполиком"',
            description: 'Лауреат государственной премии в области науки и техники (2001 г.). Имеет более 10 авторских свидетельств СССР и патентов РФ, более 40 научных работ. При непосредственном участии были разработаны высокопрочные плёночные клеи конструкционного назначения, композиционные материалы клеевых марок КМК (клеевые препреги); создана экологически чистая технология получения плёночных клеёв и клеевых препрегов, а также высокоэффективная технология изготовления клееных авиационных конструкций. Совместно с КБ отрасли разработаны принципиально новые авиационные конструкции с высоким ресурсом и надёжностью. На базе НПФ "Техполиком" создано малотоннажное производство высокопрочных клеев и клеевых препрегов (КМКС и КМКУ), которые внедрены практически во всех изделиях авиакосмической техники.',
            avatar: dementieva,
        },
    ],
    specialist: [

        {
            name: "Раиса Ивановна Иванова",
            position: "Главный специалист по направлению 'Клеи для резинометаллических изделий'",
            description: "Кандидат технических наук. В НПФ 'Техполиком' при непосредственном участии выпускаются: клеи для склеивания сырых резин с металлами в процессе вулканизации, клеи для склеивания вулканизованных резин с металлами. Имеет более 20 авторских свидетельств СССР и патентов РФ.",
            avatar: ivanova,
        },
        {
            name: 'Дмитрий Викторович Батизат',
            position: 'Главный специалист по направлению "Синтез олигомеров и приготовление клеевых связующих"',
            description: 'Кандидат химических наук, ответственный за технологические процессы изготовления высокопрочных клеевых масс, осуществляет синтез компонентов клеёв и клеевых связующих, олигомеров, мономеров и полимеров, выпуск которых остался за пределами России, участвует в модернизации оборудования.',
            avatar: batizat,
        },
        {
            name: 'Алексей Алексеевич Сереженков',
            position: 'Главный специалист по направлению "Высокопрочные плёночные клеи и препреги на их основе"',
            description: 'В ООО НПФ "Техполиком" работает со дня основания, автор ряда высокопрочных клеёв и препрегов. С его непосредственным участием были разработаны технологические процессы изготовления высокопрочных клеевых масс и препрегов, он разрабатывает и оформляет научно - техническую документацию, формирует цены на продукцию ООО НПФ "Техполиком", имеет более 20 авторских свидетельств СССР и патентов РФ.',
            avatar: serejenkov,
        },
        {
            name: 'Алексей Борисович Лямин',
            position: 'Главный инженер ООО НПФ "ТЕХПОЛИКОМ"',
            description: 'Кандидат технических наук. Специалист в области создания теплоаккумулирующих материалов, алюмостеклопластиков типа СИАЛ. Разносторонние знания способствуют успешной разработке технической политики и технического развития предприятия; пути реконструкции и технического перевооружения работающего производства, уровень специализации и перспективы; обеспечивать необходимый уровень технической подготовки производства; принимать меры по эффективности увеличения производительности труда, рациональному расходу материальных, финансовых и трудовых ресурсов. Имеет 7 авторских свидетельств и патентов.',
            avatar: lyamin,
        }
    ],
    administrative: [
        {
            name: 'Михаил Валерьевич Слуцкий',
            position: 'Управляющий директор',
            description: 'Подполковник, окончил Военную академию экономики, финансов и права при ВС РФ, юрист. Знания в области гражданского права, трудовых и экономических отношений, а также неоценимый опыт работы в государственных структурах позволяют создавать наиболее благоприятные условия для положительного решения экономических и организационных вопросов фирмы. Осуществляет взаимодействие с заказчиками и решает юридические вопросы компании.',
            avatar: sluckii,
        },
        {
            name: 'Солёнов Павел Олегович',
            position: 'Директор производства в г. Ярославль',
            description: 'Руководит всей деятельностью обособленного подразделения ООО НПФ "Техполиком" в г. Ярославль.',
            avatar: solyonov,
        },
        {
            name: 'Нуждин Георгий Анатольевич',
            position: 'Заместитель генерального директора по качеству',
            description: 'Кандидат технических наук, осуществляет строгий контроль соблюдения регламента по качеству на производстве НПФ "Техполиком", а также в обособленном подразделении в г. Ярославль.',
            avatar: nujdin,
        },
        {
            name: 'Галина Николаевна Пилясова',
            position: 'Секретарь - референт',
            description: 'Безупречно проводит работу по учёту прохождения производства продукции от заявки до поставки готовой продукции. Благодаря своему многолетнему опыту, профессионализму, трудоспособности и чуткости она находит общий язык со всеми потребителями нашей продукции и оперативо решает острые вопросы во время рабочего процесса.',
            avatar: pilyasova,
        },
        {
            name: 'Мишина Надежда Петровна',
            position: 'И.О. главного бухгалтера',
            description: 'Особенность бухгалтерии - это абсолютная прозрачность прохождения всей бухгалтерской документации, включая формирование цен на продукцию, зарплат в условиях сдельной оплаты, расход материалов и инвентаризацию.Помогает нам подтверждать правильность ведения бухгалтерского учёта тесное сотрудничество с фирмой "Национальный стандарт".',
            avatar: mishina,
        },
        {
            name: 'Елена Николаевна Устинова',
            position: 'Ведущий специалист отдела кадров',
            description: 'Совмещает работу менеджера по обеспечению производства сырьём, диспетчера транспортного цеха, специалиста по кадрам. Профессионализм, ответственость и энергия помогают проводить эту кропотливую, требующую внимания и терпения работу.',
            avatar: ustinova,
        }
    ],
}

export default staffData;
