import React from "react";
import './App.css'
import {Route, Routes, RedirectFunction, Navigate} from "react-router-dom";
import Header from "./components/Header/Header.jsx";
import Footer from "./components/Footer/Footer.jsx";
import Home from "./components/Home/Home.jsx";
import About from "./components/About/About.jsx";
import Licenses from "./components/Licenses/Licenses.jsx";
import licensesData from "./components/Licenses/licenses-data.js";

function App() {

  return (
    <>
        <Header/>
            <Routes>
                <Route
                    exact
                    path={'/'}
                    element={<Navigate to={'/main'} replace />}
                />
                <Route
                    path={'/main/:block?'}
                    element={<Home/>}
                />
                <Route
                    path={'/about'}
                    element={<About />}
                />
                <Route
                    path={'/licenses'}
                    element={<Licenses data={licensesData}/>}
                />
            </Routes>
        <Footer/>
    </>
  )
}

export default App
