import license1 from '../../assets/licenses/1.jpeg';
import license2 from '../../assets/licenses/2.jpeg';
import license3 from '../../assets/licenses/3.jpeg';
import license4 from '../../assets/licenses/4.jpeg';
import certificate1 from '../../assets/certificates/1.jpeg';
import certificate2 from '../../assets/certificates/2.jpeg';
import certificate3 from '../../assets/certificates/3.jpeg';
import certificate4 from '../../assets/certificates/4.jpeg';
import certificate5 from '../../assets/certificates/5.jpeg';

const licensesData = {
    licenses: [
        {
            img: license1,
            alt: 'Лицензия на осуществление деятельности по разработке, производству, испытанию и ремонту авиационной техники (страница 1)'
        },
        {
            img: license2,
            alt: 'Лицензия на осуществление деятельности по разработке, производству, испытанию и ремонту авиационной техники (страница 2)',
        },
        {
            img: license3,
            alt: 'Лицензия на осуществление деятельности по разработке, производству, испытанию и ремонту авиационной техники (страница 3)',
        },
        {
            img: license4,
            alt: 'Лицензия на осуществление деятельности по разработке, производству, испытанию и ремонту авиационной техники (страница 4)',
        },
    ],
    certificates: [
        {
            img: certificate1,
            alt: 'Сетификат соответствия',
        },
        {
            img: certificate2,
            alt: 'Свидетельство о постановке на учёт в налоговом органе',
        },
        {
            img: certificate3,
            alt: 'Сертификат на производство авиационных материалов',
        },
        {
            img: certificate4,
            alt: 'Аттестат испытательной лаборатории',
        },
        {
            img: certificate5,
            alt: 'Заключение о состоянии измерений лаборатории',
        },
    ],
}

export default licensesData;
