const companyHistoryTexts = [
    'Научно-производственная фирма "Техполиком" была создана на базе Всесоюзного Института Авиационных Материалов (ВИАМ) в 90-е годы по инициативе руководителя научно-исследовательского подразделения Батизата Виктора Пантелеевича. Создание компании осуществлялось при поддержке руководства института с целью сохранения уникальных разработок в области конструкционных клеев и материалов на их основе в период отсутствия государственного финансирования.',
    'В период перестройки многие химические производства были закрыты, в том числе научно-исследовательские и производственные предприятия. Кроме того, производство отдельных компонентов, необходимых для создания клеящих материалов конструкционного назначения, было вынесено за пределы России, что привело к риску прерывания поставок для авиационной промышленности. Клеи необходимы для производства практически всех летательных аппаратов, включая те, которые определяют обороноспособность России.',
    'Технические характеристики высокопрочных клеев, разрабатываемых на Всесоюзном Институте Авиационных Материалов (ВИАМ), не имели мировых аналогов. Они были специально созданы с учетом сложных климатических условий, от тропиков до вечной мерзлоты, а также с учетом особенностей отечественного производства. Эти клеи являлись неотъемлемой частью производства высококачественных летательных аппаратов, обеспечивая безопасность полетов и защищая интересы России на мировой арене.',
    'В период перестройки негативные изменения затронули Всесоюзный научно-исследовательский институт авиационных материалов (ВИАМ), который был главным предприятием по разработке материалов для изделий авиакосмической техники, включая клеящие материалы. Это создало угрозу бесперебойного обеспечения авиационной промышленности конструкционными клеями, которые являются необходимыми для производства практически всех летательных аппаратов, включая те, которые определяют обороноспособность России.',
    'С целью сохранения престижа России в области разработки конструкционных клеев авиационного назначения и предотвращения вытеснения отечественных материалов иностранными фирмами, начальник лаборатории клеев и технологии склеивания ВИАМ, Батизат Виктор Пантелеевич, и ведущие сотрудники лаборатории организовали научно-производственную фирму "Техполиком" на базе ВИАМ. Сначала фирма существовала в рамках одной из многочисленных холдинговых компаний тех времен, а в январе 1993 года она выделилась в самостоятельное предприятие.',
    'В начале своей деятельности сотрудники фирмы Техполиком осуществляли научную работу в рамках научных программ ФГУП ВИАМ, а также выполняли заказы на производство небольших партий клеев, в основном для ремонтных предприятий. Это позволило создать технический и финансовый задел будущего производства, а также подготовить кадры, которые в дальнейшем стали сотрудниками фирмы. Основными направлениями деятельности фирмы стали разработка и внедрение конструкционных клеев и материалов на их основе, создание наукоемких технологий, организация малотоннажного производства авиационных клеящих материалов и установление эффективных связей с предприятиями отрасли.',
    'В настоящее время ООО НПФ «Техполиком» располагает двумя технологическими площадками для выпуска клеев и клеевых препрегов, а также современной лабораторией, оснащенной передовым исследовательским и испытательным оборудованием. Мощность созданного производства полностью удовлетворяет потребности наших заказчиков.'
]

export default companyHistoryTexts;
