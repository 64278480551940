import React from 'react';
import './partners.scss';

const Partners = React.forwardRef(({data}, ref) => {
    return (
        <section className="section partners" id="partners" ref={ref}>
            <div className="container partners__container">
                <h2>Наши партнёры</h2>
                <ul className="partners__list">
                    {data.map((partner, index) => (
                        <li key={index} className="partners__item flip flip-vertical">
                            <div className="front">
                                <img src={partner.logo} alt="Логотип компании" />
                            </div>
                            <div className="back">
                                <h6>{partner.name}</h6>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
});

export default Partners;
