import './header.scss';
import mainLogo from '../../assets/30_tpk_logo.svg';
import Navigation from "../Navigation/Navigation.jsx";
import {NavLink, useLocation} from "react-router-dom";
function Header() {
    const location = useLocation();

    return (
        <header className={'section header'}>
            <div className={'container header__container'}>
                <div className={'header__introduction'}>
                    {location.pathname === '/about' || location.pathname === '/licenses'
                        ?
                        <NavLink to={'/main'} className={'header__logo'}>
                            <img className="header__logo" src={mainLogo} alt="logo"/>
                        </NavLink>
                        :
                        <img className="header__logo" src={mainLogo} alt="logo"/>
                    }
                    <div className="header__text">
                    <h1 className="header__title">Научно-производственная фирма &laquo;Техполиком&raquo;</h1>
                        <p className="header__subtitle">30&nbsp;лет производим лучшие клеевые композиции
                            в&nbsp;России</p>
                    </div>
                </div>
                <Navigation/>
            </div>
        </header>
);
}

export default Header;
