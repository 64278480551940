import React from 'react';
import './companyHistory.scss';

function CompanyHistory({data}) {
    return (
        <>
            <section className="section section-secondary">
                <h2 className="history__title">История компании</h2>
                <ul className="history__list">
                    {data.map((text, index) => (
                        <li key={index} className="history__paragraph">
                            <p>
                                {text}
                            </p>
                        </li>
                    ))}
                </ul>
            </section>
        </>
    );
}

export default CompanyHistory;
