import React from 'react';
import "./contacts.scss";

const Contacts = React.forwardRef(({...props}, ref) => {
    return (
        <section className="section contacts" id={'contacts'} ref={ref}>
            <div className="container contacts__container">
                <h2>Контактная информация</h2>

                <div className="contacts__text-wrapper">
                    <h4 className="contacts__title">
                        Общество с ограниченной ответственностью
                        <br/>Научно-производственная фирма "Техполиком"
                    </h4>
                    <ul className="contacts__list">
                        <li className="contacts__item">
                            <h4>Адрес</h4>
                            <p>111024 г. Москва, Андроновское шоссе дом.26 стр.3</p>
                        </li>
                        <li className="contacts__item">
                            <h4>Часы работы</h4>
                            <p>Понедельник - Четверг, с 10 до 16</p>
                        </li>
                        <li className="contacts__item">
                            <h4>Факс и телефоны</h4>
                            <div className="contacts__item-tel-wrapper">
                                <ul>
                                    <li>
                                        <p><a href="tel:+74956003296">+7(495) 600-32-96</a> — многоканальный, общие вопросы</p>
                                    </li>
                                    <li>
                                        <p><a href="tel:+79253004633">+7(925) 300-46-33</a> — склад</p>
                                    </li>
                                    <li>
                                        <p><a href="tel:+79253004623">+7(925) 300-46-23</a> — бухгалтерия</p>
                                    </li>
                                    <li>
                                        <p><a href="tel:+79253004634">+7(925) 300-46-34</a> — лаборатория</p>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="contacts__item">
                            <h4>E-mail</h4>
                            <a href="mailto:npftpk@rambler.ru">npftpk@rambler.ru</a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
});

export default Contacts;
