import React, {useEffect, useRef} from 'react';
import Slider from "../Slider/Slider.jsx";
import Information from "../Information/Information.jsx";
import Products from "../Products/Products.jsx";
import Partners from "../Partners/Partners.jsx";
import partnersData from "../Partners/partners-data.js";
import Contacts from "../Contacts/Contacts.jsx";
import YaMap from "../YaMap/YaMap.jsx";
import {useParams} from "react-router-dom";

const Home = () => {
    const params = useParams();
    const blocks = {
        products: useRef(null),
        partners: useRef(null),
        contacts: useRef(null)
    };

    const scrollToBlock = (block = "") => {
        if (block in blocks) {
            blocks[block].current.scrollIntoView({
                behavior: "smooth"
            });
        }
    };

    useEffect(() => {
        scrollToBlock(params.block);
    }, [params]);

    return (
        <>
            <div className="home">
                <Slider/>
                <Information/>
                <Products ref={blocks.products}/>
                <Partners ref={blocks.partners} data={partnersData}/>
                <Contacts ref={blocks.contacts}/>
                <YaMap/>
            </div>
        </>
    );
};

export default Home;
