import React from 'react';
import './about.scss';
import CompanyHistory from "../CompanyHistory/CompanyHistory.jsx";
import companyHistoryData from "../CompanyHistory/companyHistory-data.js";
import Staff from "../Staff/Staff.jsx";
import staffData from "../Staff/staff-data.js";
import ReactPlayer from "react-player/youtube";
const About = () => {
    return (
        <div className="about">
            <section className="section section-secondary">
                <h1 className="about__title">О компании</h1>
                <div className="about__video-wrapper">
                    <ReactPlayer
                        url={'https://www.youtube.com/embed/YT8ycnk2-LA'}
                        controls={true}
                        playing={true}
                        width={'100%'}
                        height={'100%'}
                        volume={0.4}
                    />
                </div>
            </section>
            <CompanyHistory data={companyHistoryData} />
            <Staff data={staffData} />
        </div>
    );
};

export default About;
