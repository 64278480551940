import React, {useEffect} from 'react';
import './popup.scss';

const Popup = (props) => {

    function handleOverlayClose(evt) {
        evt.stopPropagation();
        if (evt.target.classList.contains('open')) {
            props.closePopup();
        }
    }

    function handleEscClose(evt) {
        if (evt.key === 'Escape') {
            props.closePopup();
        }
    }

    useEffect(() => {
        if (props.isPopupOpen) {
            document.addEventListener('keydown', handleEscClose);
        }

        return () => {
            document.removeEventListener('keydown', handleEscClose);
        }

    }, [props.isPopupOpen]);

    return (
        <div onClick={handleOverlayClose} className={`popup ${props.isPopupOpen ? 'open' : ''}`}>
            <div className="popup__content">
                <button className="popup__close" onClick={props.closePopup} aria-label={'Кнопка закрытия попапа'}/>
                <img className="popup__img" src={props.selectedImg} alt={props.selectedImgAlt} />
                <p className="popup__name">{props.selectedImgAlt}</p>
            </div>
        </div>
    );
};

export default Popup;
