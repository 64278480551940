import './footer.scss';
import {NavLink, useLocation} from "react-router-dom";

function Footer(mainPage) {
    // получаем текущий год
    const currentYear = new Date().getFullYear();
    const location = useLocation();

    return (
        <footer className="footer">
            <div className="footer__decorative-line"></div>
            <div className="container footer__container">
                <ul className="footer__breadcrumbs-list">
                    {location.pathname === '/main'
                        || location.pathname === '/main/partners'
                        || location.pathname === '/main/contacts'
                        || location.pathname === '/main/products'
                        ?
                        ''
                        :
                        <li className="footer__breadcrumbs-item">
                            <NavLink to={'main'}>На главную</NavLink>
                        </li>
                    }
                    {location.pathname === '/licenses'
                        ?
                        ''
                        :
                        <li className="footer__breadcrumbs-item">
                            <NavLink to={'licenses'}>Лицензии и сертификаты</NavLink>
                        </li>
                    }
                    {location.pathname === '/about'
                        ?
                        ''
                        :
                        <li className="footer__breadcrumbs-item">
                            <NavLink to={'about'}>О компании</NavLink>
                        </li>
                    }

                </ul>
                <p className="footer__copyright">&copy;1993-{currentYear} Научно-производственная фирма &laquo;Техполиком&raquo;</p>
            </div>
        </footer>
    )
}

export default Footer;
