import './navigation.scss';
import {NavLink} from "react-router-dom";

function Navigation() {

    return (
        <nav className={'nav'}>
            <ul className={'nav__list'} id={'nav-list'}>
                <li className={'nav__item'}>
                    <NavLink to={'/about'}>О&nbsp;компании</NavLink>
                </li>
                <li className={'nav__item'}>
                    <NavLink to={'/main/products'}>Продукция</NavLink>
                </li>
                <li className={'nav__item'}>
                    <NavLink to={'/main/partners'}>Партнёры</NavLink>
                </li>
                <li className={'nav__item'}>
                    <NavLink to={'/main/contacts'}>Контакты</NavLink>
                </li>
                <li className={'nav__item'}>
                    <NavLink to={'/licenses'}>Лицензии</NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default Navigation;
