import React, { useState } from 'react';
import './itcAccordion.scss';

const Accordion = ({ items, alwaysOpen = false }) => {

    const [openItem, setOpenItem] = useState(alwaysOpen ? 0 : null);

    const toggleItem = (index, id) => {
        setOpenItem((prevIndex) => (prevIndex === index ? null : index));
        document.querySelector(`#${id}`).scrollIntoView(true)
    };

    return (
        <div className="accordion">
            {items.map((item, index) => (
                <div
                    key={index}
                    className={`accordion__item ${index === openItem ? 'accordion__item_show' : ''}`}
                    id={`accordion${index}`}
                >
                    <div className={`accordion__header ${index === openItem ? 'active' : ''}`}
                         onClick={() => toggleItem(index, (`accordion${index}`))}

                    >
                        <h3>{item.title}</h3>
                    </div>
                    <div
                        className={`accordion__body ${index === openItem ? 'collapse' : 'collapsing'}`}
                    >
                        <div className={'accordion__content'}>
                            <div className="accordion__description">
                                {item.previews.map((preview, index) => (
                                    <p key={index}>
                                        {preview}
                                    </p>
                                ))}
                            </div>
                            {item.firstMaterial ? (
                                <>
                                    <h4>{item.firstMaterial.title}</h4>
                                    <ol className="accordion__glue-list">
                                        {item.firstMaterial.materialsList.map((material, index) => (
                                            <li key={index} className="accordion__glue-item">
                                                <p>{material.name}</p>
                                                <p>{material.document}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </>
                            ) : ('')}
                            {item.secondMaterial ? (
                                <>
                                    <h4>{item.secondMaterial.title}</h4>
                                    <ol className="accordion__glue-list">
                                        {item.secondMaterial.materialsList.map((material, index) => (
                                            <li key={index} className="accordion__glue-item">
                                                <p>{material.name}</p>
                                                <p>{material.document}</p>
                                            </li>
                                        ))}
                                    </ol>
                                </>
                            ) : ('')}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Accordion;
