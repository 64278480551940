import React from 'react';
import './products.scss';
import ItcAccordion from "../ItcAccordion/itcAccordion.jsx";
import products from "../ItcAccordion/accordion-data.js";

const Products = React.forwardRef(({...props}, ref)=> {
    return (
        <section className="section products" id={'products'} ref={ref}>
            <div className="container products__wrapper">
                <div className="products__text-wrapper">
                    <h2>Продукция</h2>
                </div>
                <div className="products__accordion">
                    <ItcAccordion alwaysOpen={false} items={products}/>
                </div>
                <div className="products__text-wrapper">
                    <ul className="products__terms-list">
                        <li className="products__terms-item">
                            <h4>Условия</h4>
                            <p>Мы&nbsp;работаем по&nbsp;договорам поставки или по&nbsp;гарантийным письмам с&nbsp;юридическими лицами.</p>
                        </li>
                        <li className="products__terms-item">
                            <h4>Оплата</h4>
                            <ul>
                                <li>
                                    <p>
                                        Безналичная оплата по&nbsp;счёту производится в&nbsp;течение 10&nbsp;банковских дней, если иное не&nbsp;оговорено договором.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Для получения счета пришлите заявку с&nbsp;указанием наименования продукции, количества, необходимой фасовки, реквизитов для выставления счета и&nbsp;других сведений. Заявки принимаются по&nbsp;факсу, e-mail или телефону.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        При нарушении сроков оплаты поставщик имеет право в&nbsp;одностороннем порядке аннулировать счет или пересмотреть цены по&nbsp;данному счёту.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li className="products__terms-item">
                            <h4>Сроки</h4>
                            <p>Срок наработки заказов&nbsp;&mdash; не&nbsp;более 30&nbsp;дней с&nbsp;момента оплаты счета. Более подробно о&nbsp;сроках изготовления конкретной продукции можно узнать, позвонив по&nbsp;телефону <a href="tel:+74956003296">+7(495)600-32-96.</a></p>
                        </li>
                        <li className="products__terms-item">
                            <h4>Доставка</h4>
                            <ul>
                                <li>
                                    <p>
                                        <span className="attention-text">Внимание!</span> Отгрузка производится только при наличии оригинала доверенности грузополучателя на&nbsp;получение продукции.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Поставка товара осуществляется самовывозом по&nbsp;адресу: 111024, г.Москва,&nbsp;Андроновское&nbsp;ш., д.&nbsp;26,&nbsp;стр.&nbsp;3. Дата&nbsp;и&nbsp;время получения товара согласовывается не&nbsp;позднее, чем за&nbsp;1&nbsp;день по&nbsp;телефонам: <a href="tel:+79253004633">+7(925)300-46-33(склад)</a>, <a href="tel:+74956003296">+7(495)600-32-96</a>.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        По&nbsp;вопросам, связанным с&nbsp;оформлением отгрузочных документов обращаться в&nbsp;бухгалтерию по&nbsp;телефону <a href="tel:+79253004623">+7(925)300-46-23</a>.
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
});

export default Products
