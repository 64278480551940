import React, {useState} from 'react';
import './licenses.scss';
import Popup from "../Popup/Popup.jsx";
const Licenses = ({data}) => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedImageAlt, setSelectedImageAlt] = useState('');

    const openPopup = (evt) => {
        setSelectedImage(evt.target.src);
        setSelectedImageAlt(evt.target.alt);
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
        setSelectedImage('');
        setSelectedImageAlt('');
    };
    return (
        <section className="section section-secondary license-certificate">
            <h1>Лицензии и сертификаты</h1>
            <p>
                Деятельность по&nbsp;производству материалов для авиационной техники подлежит обязательной сертификации
                по&nbsp;Закону.<br/>Выпуск продукции НПФ &laquo;Техполиком&raquo; осуществляется по&nbsp;лицензии
                Минпромторга России &#8470;&nbsp;14493-АТ от&nbsp;23&nbsp;января 2019&nbsp;года, по&nbsp;осуществлению
                деятельности по&nbsp;разработке, производству, испытанию и&nbsp;ремонту авиационной техники.
            </p>
            <ul className="license-certificate__list license">
                {data.licenses.map((photo, index) => (
                    <li key={index} className="license-certificate__item">
                        <img
                            className="license-certificate__pic"
                            src={photo.img}
                            alt={photo.alt}
                            onClick={openPopup}
                        />
                    </li>
                ))}
            </ul>
            <p>
                На&nbsp;предприятии контроль качества и&nbsp;приёмку материалов осуществляет Центральное техническое
                представительство (ЦТП) АО&nbsp;&laquo;РТ-Техприёмка&raquo;.<br/>Также
                в&nbsp;НПФ &laquo;Техполиком&raquo; внедрена система менеджмента качества (СМК) с&nbsp;2006 года
                и&nbsp;сертифицирована на&nbsp;соответствие требованиям ГОСТ Р&nbsp;ИСО 9001-2015 (ISO 9001:2015)
                применительно к&nbsp;продукции собственного производства.
            </p>
            <ul className="license-certificate__list certificate">
                {data.certificates.map((photo, index) => (
                    <li key={index} className="license-certificate__item">
                        <img
                            className="license-certificate__pic"
                            src={photo.img}
                            alt={photo.alt}
                            onClick={openPopup}
                        />
                    </li>
                ))}
            </ul>
            <Popup
                isPopupOpen={isPopupOpen}
                closePopup={closePopup}
                selectedImg={selectedImage}
                selectedImgAlt={selectedImageAlt}
            />
        </section>
    );
};

export default Licenses;
