import vasoLogo from '../../assets/partners/VASO.png';
import aviakompozitLogo from '../../assets/partners/Aviakompozit.webp';
import kvzLogo from '../../assets/partners/KVZ-logotip.jpg';
import tupolevLogo from '../../assets/partners/KAZ.png';
import sukhoiLogo from '../../assets/partners/sukhoi.png';
import energyLogo from '../../assets/partners/energia.jpeg';
import reshetnevaLogo from '../../assets/partners/iss.jpeg';
import gagarinaLogo from '../../assets/partners/knaapo.jpeg';
import irkutLogo from '../../assets/partners/irkut.png';
import uazLogo from '../../assets/partners/UUAZ.png';
import rostvertolLogo from '../../assets/partners/Rostvertol.png';
import technologyLogo from '../../assets/partners/ONPP-Tehnologiya_-Obninsk_Logo-215h200.png';
/*import berievaLogo from '../../assets/partners/TANTK_Berieva.png';*/
import migLogo from '../../assets/partners/mig.png';
import vperedLogo from '../../assets/partners/Mmz-vpered.png';
import kronshtadtLogo from '../../assets/partners/kronschtadt.png';
import iohRanLogo from '../../assets/partners/ioh-ran-logo@2x.png'

const partnersData = [
    {
        logo: iohRanLogo,
        name: 'Институт органической химии им. Н.Д. Зелинского Российской академии наук (более 20 лет сотрудничества)'
    },
    {
        logo: vasoLogo,
        name: 'ПАО «Воронежское акционерное самолетостроительное общество»',
    },
    {
        logo: aviakompozitLogo,
        name: 'ЗАО «Авиакомпозит»',
    },
    {
        logo: kvzLogo,
        name: 'ПАО «Казанский вертолетный завод»',
    },
    {
        logo: tupolevLogo,
        name: 'КАЗ им.С.П.Горбунова филиал ПАО "Туполев"',
    },
    {
        logo: sukhoiLogo,
        name: 'ОАО "ОКБ Сухого"',
    },
    {
        logo: energyLogo,
        name: 'Ракетно космическая корпорация "Энергия" им. С.П. Королёва',
    },
    {
        logo: reshetnevaLogo,
        name: 'ОАО "Информационные спутниковые системы им. ак. М.Ф. Решетнева"',
    },
    {
        logo: gagarinaLogo,
        name: 'ОАО "АО им. Ю.А. Гагарина" (ОАО "КнААПО")',
    },
    {
        logo: irkutLogo,
        name: 'ПАО Научно-производственная корпорация "Иркут"',
    },
    {
        logo: uazLogo,
        name: 'ОАО "Улан-Уденский авиационный завод"',
    },
    {
        logo: rostvertolLogo,
        name: 'ПАО "Роствертол"',
    },
    {
        logo: technologyLogo,
        name: 'ФГУП ОНПП "Технология"',
    },
    /*{
        logo: berievaLogo,
        name: 'ОАО ТАНТК им. Г.Н. Бериева',
    },*/
    {
        logo: migLogo,
        name: 'АО "Российская самолетостроительная корпорация МИГ"',
    },
    {
        logo: vperedLogo,
        name: 'АО "Московский машиностроительный завод Вперед"',
    },
    {
        logo: kronshtadtLogo,
        name: 'Российская высокотехнологичная компания "КРОНШТАДТ"',
    },
];

export default partnersData;
