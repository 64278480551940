import {useEffect, useState} from "react";
import './slider.scss';
import firstSlide from '../../assets/slider/index-1.jpg';
import secondSlide from '../../assets/slider/index-2.jpg';
import thirdSlide from '../../assets/slider/index-3.jpg';
import fourthSlide from '../../assets/slider/index-4.jpg';

const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        {id: 0, image: firstSlide, title: 'Клеи для резинометаллических изделий'},
        {id: 1, image: secondSlide, title: 'Высокопрочные высокоэластичные плёночные клеи'},
        {id: 2, image: thirdSlide, title: 'Эпоксидные высокопрочные клеи холодного отверждения'},
        {id: 3, image: fourthSlide, title: 'Клеевые препреги КМКС и КМКУ'},
    ]

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentSlide === 3) {
                setCurrentSlide(0);
            } else {
                setCurrentSlide((currentSlide + 1) % slides.length);
            }
        }, 4000);

        return () => {
            clearInterval(interval);
        };
    }, [currentSlide, slides.length]);

    return (
        <section className="slider">
            <div className="container slider__container">
                {slides.map((slide) => (
                    <div
                        key={slide.id}
                        className={`slide ${slide.id === currentSlide ? 'active' : ''}`}
                    >
                        <img src={slide.image} alt={slide.title}/>
                    </div>
                ))}
            </div>
        </section>
    );

}

export default Slider;
