import React from 'react';
import './staff.scss';

const Staff = ({data}) => {

    return (
        <section className="section section-secondary">
            <h2 className="history__title">Сотрудники компании</h2>
            <ul className="staff__list">
                <li className="staff__item">
                    <h3>Руководители компании</h3>
                    <ul>
                        {data.leader.map((leader, index) => (
                            <li key={index}>
                                <div className="staff__avatar-wrapper">
                                    <img src={leader.avatar} alt={'Аватар'}/>
                                </div>
                                <div className="staff__text-wrapper">
                                    <div className="staff__name">
                                        <h4>{leader.name}</h4>
                                        <p>{leader.position}</p>
                                    </div>
                                    <p className="staff__description">{leader.description}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </li>
                <li className="staff__item">
                    <h3>Главные специалисты</h3>
                    <ul>
                        {data.specialist.map((specialist, index) => (
                            <li key={index}>
                                <div className="staff__avatar-wrapper">
                                    <img src={specialist.avatar} alt={'Аватар'}/>
                                </div>
                                <div className="staff__text-wrapper">
                                    <div className="staff__name">
                                        <h4>{specialist.name}</h4>
                                        <p>{specialist.position}</p>
                                    </div>
                                    <p className="staff__description">{specialist.description}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </li>
                <li className="staff__item">
                    <h3>Административная группа</h3>
                    <ul>
                        {data.administrative.map((admin, index) => (
                            <li key={index}>
                                <div className="staff__avatar-wrapper">
                                    <img src={admin.avatar} alt={'Аватар'}/>
                                </div>
                                <div className="staff__text-wrapper">
                                    <div className="staff__name">
                                        <h4>{admin.name}</h4>
                                        <p>{admin.position}</p>
                                    </div>
                                    <p className="staff__description">{admin.description}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </li>
            </ul>
        </section>
    );
};

export default Staff;
