import React from 'react';
import {Clusterer, Placemark, YMaps, Map} from "@pbe/react-yandex-maps";
import './yandexmap.scss';

const MapComponent = () => {
    const API_KEY = '62deecd8-add4-4cab-bfbc-ace953205a3a';

    return (
        <div className={'container yandex-map'}>
            <YMaps query={{
                lang: 'ru_RU',
                load: 'package.full',
                apikey: API_KEY
            }}>
                <Map
                    defaultState={{ center: [55.738409, 37.730400], zoom: 17 }}
                    width='100%'
                    height='400px'
                >
                    <Clusterer>
                        <Placemark
                            geometry={[55.738409, 37.730400]}
                            properties={{
                                hintContent: 'Техполиком',
                                balloonContent: 'Клеящие вещества и материалы в Москве',
                            }}
                            options={{
                                preset: 'islands#icon',
                                iconImageSize: [60, 60],
                                iconColor: '#ff0000',
                                hideIconOnBalloonOpen: true,
                                openEmptyHint: true
                            }}
                        />
                    </Clusterer>
                </Map>
            </YMaps>
        </div>
    );
}

export default MapComponent;
